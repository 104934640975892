import {
  IonAlert,
  IonIcon,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonText,
  isPlatform,
} from '@ionic/react';
import { addCircleOutline, homeOutline, personCircleOutline } from 'ionicons/icons';
/*import {
    About, AnnoncesModerator, ModerateAnnonces, AnnoncesUser, Blog,
    ChangePassword, Contact, Home,
    Login, NotFound,
    Privacy, Profile, Register,
    ResetPassword, Search,
    SearchDetail, SendPost, UpdatePost, ModerateAnnonceForm
} from "pages";*/
import React, { Component, lazy } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Redirect, Route, RouteComponentProps } from 'react-router';
import { constants, Events } from 'utils';
import { getLanguage, removeUserProfile, removeUserToken } from 'utils/Functions/Functions';
import { FlahaState } from 'utils/Interfaces/Interfaces';
import './Navigation.scss';
import { langFunction } from 'lang';
import { lang } from 'utils/Types/types';

const KitMediaModerator = lazy(() => import('pages/KitMediaModerator/KitMediaModerator'));
const ContactModerator = lazy(() => import('pages/ContactModerator/ContactModerator'));

const AnnoncesModerator = lazy(() => import('pages/AnnoncesModerator/AnnoncesModerator'));
const ModerateAnnoncesAdmin = lazy(() => import('pages/ModerateAnnoncesAdmin/ModerateAnnoncesAdmin'));
const ModerateAnnonces = lazy(() => import('pages/ModerateAnnonces/ModerateAnnonces'));
const Login = lazy(() => import('pages/Login/Login'));
const NotFound = lazy(() => import('pages/NotFound/NotFound'));
const ModerateAnnonceForm = lazy(() => import('pages/ModerateAnnonceForm/ModerateAnnonceForm'));
const ModerateShops = lazy(() => import('pages/ModerateShops/ModerateShops'));
const ModerateFundRequests = lazy(() => import('pages/ModerateFundRequests/ModerateFundRequests'));
const ModerateBoostRequests = lazy(() => import('pages/ModerateBoostRequests/ModerateBoostRequests'));
const DisableAnnonces = lazy(() => import('pages/DisableAnnonces/DisableAnnonces'));
const LeadsModerator = lazy(() => import('pages/LeadsModerator/LeadsModerator'));
const AnalyticsDashboard = lazy(() => import('pages/AnalyticsDashboard/AnalyticsDashboard'));
const Admin = lazy(() => import('pages/Admin/Admin'));

class Navigation extends Component<Props, State> {
  link = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);

  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {
      lang: 'ar',
      token: null,
      showUpdateMsg: false,
    };
    this.checkRole = this.checkRole.bind(this);
    this.getInitialLanguage();
  }

  async getInitialLanguage() {
    await getLanguage().then((result: lang) => {
      this.setState({ lang: result });
    });
  }

  componentDidMount(): void {
    this.getToken();
    Events.subscribe(constants.lang_change, ({ lang }) => {
      this.setState({
        lang: lang,
      });
    });
  }

  getToken() {
    this.setState({ token: localStorage.getItem('@flaha.userToken') });
  }

  removeToken() {
    removeUserToken();
    removeUserProfile();
    const url = `${process.env.REACT_APP_API_URL}${constants.logout}`;
    const headers = new Headers({
      'Content-Type': 'application/json',
    });
    fetch(url, {
      method: 'GET',
      credentials: 'same-origin',
      headers: headers,
    }).then((result) => {
      if (result.status === 200) {
      }
    });
    this.setState({ token: null });
  }

  checkRole(role: string) {
    let token = localStorage.getItem('@flaha.userToken');
    if (!token) {
      return false;
    }
    let roles = JSON.parse(localStorage.getItem('@flaha.userRoles'));
    let existRole = false;
    roles
      ? roles.forEach((row) => {
          if (row.role.toLowerCase() === role) {
            existRole = true;
          }
        })
      : (existRole = false);
    return existRole;
  }

  handleAlertClose() {
    this.setState({ showUpdateMsg: false });
  }

  render() {
    const { token, showUpdateMsg, lang } = this.state;

    let isModerator = localStorage.getItem('@flaha.userModerator');
    let isAdmin = localStorage.getItem('@flaha.userAdmin');
    // @ts-ignore
    const { intl } = this.props;

    const baseURL = process.env.REACT_APP_MAIN_DOMAIN_URL;
    return (
      <>
        {isPlatform('capacitor') && (
          <IonAlert
            isOpen={showUpdateMsg}
            message={intl.formatMessage(langFunction.updateMessage)}
            cssClass="update-alert"
            buttons={[
              {
                text: intl.formatMessage(langFunction.updateMessageButton),
                role: 'confirm',
                handler: () => {
                  window.location.href = 'https://play.google.com/store/apps/details?id=ma.flaha';
                },
              },
            ]}
          />
        )}
        <IonTabs>
          <IonRouterOutlet id={'app'} dir={lang === 'fr' ? 'ltr' : 'rtl'}>
            <Route
              exact
              path={['/:lang/:region/:category/:query', '/:lang/:region/:category/']}
              render={(props) => {
                window.location.href = `${baseURL}${props.location.pathname}`;
                return null;
              }}
            />
            {/* login */}
            <Route
              path="/login"
              render={(props: RouteComponentProps) => {
                const { match, location, history, staticContext } = props;
                // @ts-ignore
                const { callback } = match.params;

                return token ? (
                  (window.location.href = '/')
                ) : (
                  <Login
                    match={match}
                    location={location}
                    history={history}
                    staticContext={staticContext}
                    callback={callback}
                  />
                );
              }}
            />
            {/*FacebookLogin*/}
            {/* <Route path="/oauth2/redirect" component={OAuth2RedirectHandler}></Route> */}

            <Route
              path="/admin"
              render={() => {
                return token ? <Admin /> : <Redirect to="/login?callback=/admin" />;
              }}
            />

            {/* mes annonces */}
            <Route
              path="/user/mes-annonces"
              render={(props) => {
                window.location.href = `${baseURL}${props.location.pathname}`;
                return null;
              }}
            />
            {/* Chat*/}

            <Route
              exact
              path="/user/chat"
              render={(props) => {
                window.location.href = `${baseURL}${props.location.pathname}`;
                return null;
              }}
            />
            {/* ChatMessage*/}

            {/* annonces moderator*/}
            <Route
              exact
              path="/user/verify-annonces"
              render={() => {
                // const {match, location, history, staticContext} = props;
                // // @ts-ignore
                // const {callback} = match.params;

                return !this.checkRole('user') ? (
                  <AnnoncesModerator />
                ) : token ? (
                  <Redirect to="/" />
                ) : (
                  <Redirect to="/login?callback=/user/verify-annonces" />
                );
              }}
            />
            {/* desactiver les annonces*/}
            <Route
              exact
              path="/user/desactiver-annonces"
              render={(props) => {
                // const {match, location, history, staticContext} = props;
                // // @ts-ignore
                // const {callback} = match.params;

                return this.checkRole('moderateur') || this.checkRole('admin') ? (
                  <DisableAnnonces intl={intl} {...props} />
                ) : token ? (
                  <Redirect to="/" />
                ) : (
                  <Redirect to="/login?callback=/moderator/desactiver-annonces" />
                );
              }}
            />
            {/* Modérer les annonces en masse */}
            <Route
              path="/moderator/modifier-annonces-en-masse"
              render={() => {
                // const {match, location, history, staticContext} = props;
                // // @ts-ignore
                // const {callback} = match.params;

                return token && (this.checkRole('moderateur') || this.checkRole('admin')) ? (
                  <ModerateAnnoncesAdmin lang={lang} intl={intl} />
                ) : (
                  <Redirect to="/login?callback=/moderator/modifier-annonces-en-masse" />
                );
              }}
            />
            {/* Modérer les annonces */}
            <Route
              path="/moderator/moderate-annonces"
              render={() => {
                // const {match, location, history, staticContext} = props;
                // // @ts-ignore
                // const {callback} = match.params;

                return this.checkRole('moderateur') || this.checkRole('admin') ? (
                  <ModerateAnnonces lang={lang} intl={intl} />
                ) : token ? (
                  <Redirect to="/" />
                ) : (
                  <Redirect to="/login?callback=/moderator/moderer-annonce" />
                );
              }}
            />
            <Route
              path="/moderator/liste-contacts"
              render={() => {
                // const {match, location, history, staticContext} = props;
                // // @ts-ignore
                // const {callback} = match.params;

                return this.checkRole('moderateur') || this.checkRole('admin') ? (
                  <ContactModerator lang={lang} intl={intl} />
                ) : token ? (
                  <Redirect to="/" />
                ) : (
                  <Redirect to="/login?callback=/moderator/liste-contacts" />
                );
              }}
            />
            <Route
              path="/moderator/liste-kitmedia"
              render={() => {
                // const {match, location, history, staticContext} = props;
                // // @ts-ignore
                // const {callback} = match.params;

                return this.checkRole('admin') ? (
                  <KitMediaModerator lang={lang} intl={intl} />
                ) : token ? (
                  <Redirect to="/" />
                ) : (
                  <Redirect to="/login?callback=/moderator/liste-kitmedia" />
                );
              }}
            />
            <Route
              path="/moderator/shops"
              render={() => {
                return this.checkRole('commercial') || this.checkRole('admin') ? (
                  <ModerateShops lang={lang} intl={intl} token={token} />
                ) : token ? (
                  <Redirect to="/" />
                ) : (
                  <Redirect to="/login?callback=/moderator/shops" />
                );
              }}
            />
            <Route
              path="/moderator/fund-requests"
              render={() => {
                return this.checkRole('commercial') || this.checkRole('admin') ? (
                  <ModerateFundRequests lang={lang} intl={intl} token={token} />
                ) : token ? (
                  <Redirect to="/" />
                ) : (
                  <Redirect to="/login?callback=/moderator/fund-requests" />
                );
              }}
            />
            <Route
              path="/moderator/boost-requests"
              render={() => {
                return this.checkRole('commercial') || this.checkRole('admin') ? (
                  <ModerateBoostRequests lang={lang} intl={intl} token={token} />
                ) : token ? (
                  <Redirect to="/" />
                ) : (
                  <Redirect to="/login?callback=/moderator/boost-requests" />
                );
              }}
            />
            <Route
              path="/ouvrir-boutique"
              render={(props) => {
                window.location.href = `${baseURL}${props.location.pathname}`;
                return null;
              }}
            />
            {/*<Route
              path={'/demande-abonnement'}
              render={() => {
                return token ? <CreateSubscription lang={lang} intl={intl} token={token} /> : <Redirect to="/login" />;
              }}
            />*/}
            <Route
              path={'/demande-solde'}
              render={(props) => {
                window.location.href = `${baseURL}${props.location.pathname}`;
                return null;
              }}
            />
            {/* Modifier Annonce */}
            <Route
              exact
              path="/:lang/user/annonce/edit/:slug"
              render={(props) => {
                window.location.href = `${baseURL}${props.location.pathname}`;
                return null;
              }}
            />
            {/* Chat Annonce */}
            <Route
              exact
              path="/:lang/user/messagerie/form/:slug"
              render={(props) => {
                window.location.href = `${baseURL}${props.location.pathname}`;
                return null;
              }}
            />
            {/* Flaha neuf Home */}
            <Route
              exact
              path={'/:lang/neuf/'}
              render={(props) => {
                window.location.href = `${baseURL}${props.location.pathname}`;
                return null;
              }}
            />
            {/* Material Detail */}
            <Route
              exact
              path={'/:lang/neuf/:vilReg/:cat/:mar/:mod/:slug'}
              render={(props) => {
                window.location.href = `${baseURL}${props.location.pathname}`;
                return null;
              }}
            />
            {/* shops Home */}
            <Route
              exact
              path={'/:lang/boutiques/'}
              render={(props) => {
                window.location.href = `${baseURL}${props.location.pathname}`;
                return null;
              }}
            />
            {/* shop Detail */}
            <Route
              exact
              path={'/:lang/:slug.htm'}
              render={(props) => {
                window.location.href = `${baseURL}${props.location.pathname}`;
                return null;
              }}
            />
            {/* shop update */}
            <Route
              exact
              path={'/:lang/user/shop/edit/:id'}
              render={(props) => {
                window.location.href = `${baseURL}${props.location.pathname}`;
                return null;
              }}
            />
            {/* shops user */}
            <Route
              path="/user/mes-boutiques"
              render={(props) => {
                window.location.href = `${baseURL}${props.location.pathname}`;
                return null;
              }}
            />
            {/* shop Dashboard */}
            <Route
              path="/user/boutique-dashboard"
              render={(props) => {
                window.location.href = `${baseURL}${props.location.pathname}`;
                return null;
              }}
            />
            {/* analytics Dashboard */}
            <Route
              path="/user/analytics-dashboard"
              render={(props) => {
                return this.checkRole('commercial') || this.checkRole('admin') ? (
                  <AnalyticsDashboard lang={lang} intl={intl} token={token} {...props} />
                ) : token ? (
                  <Redirect to="/" />
                ) : (
                  <Redirect to="/login?callback=/user/analytics-dashboard" />
                );
              }}
            />
            {/* analytics Dashboard */}
            <Route
              path="/user/leads-moderation"
              render={(props) => {
                return this.checkRole('commercial') || this.checkRole('admin') ? (
                  <LeadsModerator lang={lang} intl={intl} {...props} />
                ) : token ? (
                  <Redirect to="/" />
                ) : (
                  <Redirect to="/login?callback=/user/leads-moderation" />
                );
              }}
            />
            {/* contact commercial*/}
            <Route
              exact
              path="/:lang/contact-commercial/:slug.htm"
              render={(props) => {
                window.location.href = `${baseURL}${props.location.pathname}`;
                return null;
              }}
            />
            {/* sheep landing page */}
            <Route
              path="/souk-el-haouli"
              render={(props) => {
                window.location.href = `${baseURL}${props.location.pathname}`;
                return null;
              }}
            />
            {/* Modifier Annonce et modérer */}
            <Route
              exact
              path="/:lang/moderator/annonce/edit/:slug"
              render={() => {
                // const {match, location, history, staticContext} = props;
                // // @ts-ignore
                // const {callback} = match.params;

                return isModerator === 'true' || isAdmin === 'true' ? (
                  <ModerateAnnonceForm
                    style={{
                      backgroundImage: 'url(./assets/img/flaha2.webp)',
                      backgroundPositionY: '-16rem',
                    }}
                  />
                ) : token ? (
                  <Redirect to="/" />
                ) : (
                  <Redirect to="/login?callback=/user/messagerie-contact" />
                );
              }}
            />
            {/* profile */}
            <Route
              path="/user/profile"
              render={(props) => {
                window.location.href = `${baseURL}${props.location.pathname}`;
                return null;
              }}
            />
            {/* changePassword */}
            <Route
              path="/user/changepassword"
              render={(props) => {
                window.location.href = `${baseURL}${props.location.pathname}`;
                return null;
              }}
            />
            {/* logout */}
            <Route
              path="/logout"
              render={(props) => {
                this.removeToken();
                window.location.href = `${baseURL}${props.location.pathname}`;
                return null;
              }}
            />
            <Route
              path="/register"
              render={(props: RouteComponentProps) => {
                window.location.href = `${baseURL}${props.location.pathname}`;
                return null;
              }}
            />
            <Route
              path="/centre-aide"
              render={(props) => {
                window.location.href = `${baseURL}${props.location.pathname}`;
                return null;
              }}
            />
            <Route
              path="/resetpassword"
              render={(props) => {
                window.location.href = `${baseURL}${props.location.pathname}`;
                return null;
              }}
            />
            <Route
              path="/resetpassword/:token"
              render={(props) => {
                window.location.href = `${baseURL}${props.location.pathname}`;
                return null;
              }}
            />
            <Route
              exact
              path="/:lang/:region/:ville/:category/:slug.htm"
              render={(props) => {
                window.location.href = `${baseURL}${props.location.pathname}`;
                return null;
              }}
            />
            <Route
              path="/about"
              render={(props) => {
                window.location.href = `${baseURL}${props.location.pathname}`;
                return null;
              }}
            />
            <Route
              path="/privacy"
              render={(props) => {
                window.location.href = `${baseURL}${props.location.pathname}`;
                return null;
              }}
            />
            <Route
              exact
              path={['/contact', '/contact-signaler/:id', '/contact-publicite/:subject']}
              render={(props) => {
                window.location.href = `${baseURL}${props.location.pathname}`;
                return null;
              }}
            />
            <Route
              path="/sendPost"
              render={(props) => {
                window.location.href = `${baseURL}${props.location.pathname}`;
                return null;
              }}
            />
            <Route
              path="/"
              render={(props) => {
                window.location.href = `${baseURL}${props.location.pathname}`;
                return null;
              }}
              exact
            />

            <Route
              path="/ar"
              render={(props) => {
                window.location.href = `${baseURL}${props.location.pathname}`;
                return null;
              }}
              exact
            />
            <Route
              path="/fr"
              render={(props) => {
                window.location.href = `${baseURL}${props.location.pathname}`;
                return null;
              }}
              exact
            />
            <Route
              path={[
                '/user/verify/annonces/:category/:query/:annonceId',
                '/user/verify/annonces/:category/:query/',
                '/user/verify/annonces/:category//:annonceId',
                '/user/verify/annonces/:category/',
              ]}
              component={AnnoncesModerator}
            />
            <Route
              render={(props) => {
                window.location.href = `${baseURL}${props.location.pathname}`;
                return null;
              }}
              exact
            />
          </IonRouterOutlet>

          <IonTabBar slot="bottom">
            <IonTabButton tab="search" href={lang === 'ar' ? `/ar/المغرب/للبيع/` : `/fr/maroc/a_vendre/`}>
              <IonIcon icon={homeOutline} />
              <IonText>
                <FormattedMessage id={'annonces'} />
              </IonText>
            </IonTabButton>
            <IonTabButton tab="sendPost" href="/sendPost">
              <IonIcon icon={addCircleOutline} />
              <IonText>
                <FormattedMessage id={'depose'} />
              </IonText>
            </IonTabButton>
            {token !== null && (
              <IonTabButton tab="login" href="/login">
                <IonIcon icon={personCircleOutline} />
                <IonText>
                  <FormattedMessage id={'menu.user.sign.in'} />
                </IonText>
              </IonTabButton>
            )}
            {token === null && (
              <IonTabButton tab="profile" href="/user/profile">
                <IonIcon icon={personCircleOutline} />
                <IonText>
                  <FormattedMessage id={'account'} />
                </IonText>
              </IonTabButton>
            )}
          </IonTabBar>
        </IonTabs>
      </>
    );
  }
}

interface Props {
  lang: lang;
}

interface State extends FlahaState {
  token: string | null | undefined;
  showUpdateMsg: boolean;
}

// @ts-ignore
export default injectIntl(Navigation);
