import React, { useEffect, useState } from 'react';
import './MobileTopBanner.scss';
import { IonButton, IonCol, IonIcon, IonRow } from '@ionic/react';
import { closeCircleOutline } from 'ionicons/icons';
import { FormattedMessage } from 'react-intl';
import FileService from '../../services/FileService';
import { Campaign } from 'utils/Types/types';
import parametrageService from '../../services/ParametrageService';
import { constants } from 'utils';
import CampaignService from '../../services/CampaignService';

interface MobileTopBannerProps {
  lang: string;
}

const MobileTopBanner: React.FC<MobileTopBannerProps> = ({ lang }) => {
  const [campaign, setCampaign] = useState<Campaign>(undefined);

  const [showBanner, setShowBanner] = useState(true);
  const [showCampaignBanner, setShowCampaignBanner] = useState(false);

  const openLink = () => {
    let ua = navigator.userAgent.toLowerCase();
    let isAndroid = ua.indexOf('android') > -1;
    if (isAndroid) {
      setTimeout(function () {
        window.location.href = 'https://play.google.com/store/apps/details?id=ma.flaha';
      }, 1000);
      window.location.href = 'ma.flaha://';
    }
  };

  useEffect(() => {
    const getCampaign = (id) => {
      const options = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      CampaignService.getById(id, options)
        .then((value) => {
          setCampaign(value.data);
          if (campaign?.active) {
            setShowCampaignBanner(true);
            setShowBanner(false);
          }
        })
        .catch((reason) => {
          throw new Error(reason.message);
        });
    };

    parametrageService
      .get({
        options: {
          headers: {
            'Content-Type': 'application/json',
          },
          params: { cle: constants.MOBILE_TOP_ADUNIT_CAMPAIGN },
        },
      })
      .then((res) => {
        if (res.data.valeurFr) {
          getCampaign(res.data.valeurFr);
        } else {
          setShowCampaignBanner(false);
          setShowBanner(true);
        }
      })
      .catch((reason) => {
        setShowBanner(true);
        setShowCampaignBanner(false);
      });
  }, []);

  return (
    <>
      <div className={'banner-container'}>
        {showBanner && (
          <IonRow>
            <div className={'close-button-container'}>
              <IonButton onClick={() => setShowBanner(false)} fill={'clear'}>
                <IonIcon icon={closeCircleOutline} size={'small'} />
              </IonButton>
            </div>
            <IonCol className={'icon-container'} size={'2'}>
              <picture>
                <source type="image/webp" srcSet={'assets/logo-app-flaha.webp'} />
                <img loading="lazy" src={'assets/logo-app-flaha.png'} alt={'Flaha.ma'} />
              </picture>
            </IonCol>
            <IonCol className={'text-container'} size={'5'}>
              <h5>
                {' '}
                <FormattedMessage id={'banner.title.app'} />{' '}
              </h5>
              <FormattedMessage id={'banner.text.app'} />
            </IonCol>
            <IonCol className={'open-button-container'} size={'3'}>
              <IonButton size={'small'} onClick={() => openLink()}>
                <FormattedMessage id={'banner.open.app'} />
              </IonButton>
            </IonCol>
          </IonRow>
        )}
        {showCampaignBanner && (
          <IonRow>
            <div className={'close-button-container'}>
              <IonButton onClick={() => setShowCampaignBanner(false)} fill={'clear'}>
                <IonIcon icon={closeCircleOutline} size={'small'} />
              </IonButton>
            </div>
            <IonCol className={'icon-container'} size={'7'}>
              <picture>
                <source
                  type="image/webp"
                  srcSet={FileService.serveCampaignSmallBannerWebpPath(campaign?.smallBannerImagePath)}
                />
                <img
                  className={'img-fluid'}
                  loading="lazy"
                  decoding={'async'}
                  height={'50'}
                  src={FileService.serveCampaignSmallBannerPath(campaign?.smallBannerImagePath)}
                  alt={`${lang === 'fr' ? campaign?.sponsorFr : campaign?.sponsorAr} - ${campaign?.id}`}
                />
              </picture>
            </IonCol>
            <IonCol className={'open-button-container'} size={'3'}>
              <IonButton href={`/${lang}/contact-commercial/${campaign?.slug}.htm`} size={'small'}>
                {lang === 'fr' ? campaign?.buttonTitleFr : campaign?.buttonTitleAr}
              </IonButton>
            </IonCol>
          </IonRow>
        )}
      </div>
    </>
  );
};

export default MobileTopBanner;
