import * as React from 'react';
import { constants } from 'utils';
import { injectIntl } from 'react-intl';
import { IonGrid, IonCol, IonText } from '@ionic/react';

import './CategoriesListSearch.scss';
import { Category, lang } from 'utils/Types/types';
import { capitalize } from '@material-ui/core';

interface CategoriesListSearchProps {
  lang: lang;
  intl: any;
  region: string | null | undefined;
}

const CategoriesListSearch: React.FC<CategoriesListSearchProps> = ({ lang, region }: CategoriesListSearchProps) => {
  const [categories, setCategories] = React.useState([]);
  const formattedRegion = region ? region.replace(/-/g, ' ').replace(/_/g, ' ').replace(/[0-9]/g, '') : null;

  React.useEffect(() => {
    getCategories();
  }, []);

  const getCategories = () => {
    const url = process.env.REACT_APP_API_URL + constants.categories;

    fetch(url)
      .then((result) => {
        return result.json();
      })
      .then((jsonResult) => {
        setCategories(jsonResult);
      })
      .catch((error) => {
        console.error(error.message, error);
      });
  };

  const splitToChunks = (arrayvar, parts) => {
    let result = [];
    for (let i = parts; i > 0; i--) {
      result.push(arrayvar.splice(0, Math.ceil(arrayvar.length / i)));
    }
    return result;
  };

  const getCategoriesOptions = () => {
    const principalCategories = categories
      ? categories.filter((item: Category) => {
          return item.sousCategorie === 0;
        })
      : null;
    const subCategories = categories
      ? categories.filter((item: Category) => {
          return item.sousCategorie !== 0;
        })
      : null;

    const formattedCategories = principalCategories
      ? principalCategories.map((item: Category) => {
          const subCategoriesPerCategory = subCategories
            ? subCategories.filter((subItem: Category) => {
                return subItem.sousCategorie.toString() === item.code;
              })
            : null;

          const subCategoriesOptions = subCategoriesPerCategory
            ? subCategoriesPerCategory.map((item: Category) => {
                return {
                  id: item.id,
                  label: lang === 'fr' ? item.titreFrancais : item.titreArabe,
                  value: lang === 'fr' ? item.urlFrancais : item.urlArabe,
                  sousCategorie: item.sousCategorie,
                };
              })
            : null;
          return {
            id: item.id,
            label: lang === 'fr' ? item.titreFrancais : item.titreArabe,
            value: lang === 'fr' ? item.urlFrancais : item.urlArabe,
            nomImage: item.nomImage,
            sousCategorie: item.sousCategorie,
            options: subCategoriesOptions,
          };
        })
      : null;
    const splittedListCategories = splitToChunks(formattedCategories, 6);
    return splittedListCategories;
  };

  return (
    <div style={{ backgroundColor: 'var(--ion-color-light-tint)' }}>
      <IonGrid fixed={true} className="grid-categories-seo-container">
        {getCategoriesOptions().map( (categoriesList, index) => {
          return (
            <IonCol key={`col-${index}`}>
              {categoriesList.map((principalCategory: any, index) => {
                return (
                  <div className="principal-category-box" key={`div-principal-${index}`}>
                    <h6>{principalCategory.label}</h6>
                    <div className="sub-category-box">
                      {principalCategory.options.length !== 0 ? (
                        principalCategory.options.map((subCategory: any, index) => {
                          return (
                            <a key={`a-sub-${index}`}
                              href={
                                region
                                  ? lang === 'ar'
                                    ? `/ar/${region}/${subCategory.value}/`
                                    : `/fr/${region}/${subCategory.value}/`
                                  : lang === 'ar'
                                  ? `/ar/${region}/${subCategory.value}/`
                                  : `/fr/${region}/${subCategory.value}/`
                              }
                            >
                              <IonText>{`${subCategory.label} - ${capitalize(formattedRegion)}`}</IonText>
                            </a>
                          );
                        })
                      ) : (
                        <a
                          href={
                            region
                              ? lang === 'ar'
                                ? `/ar/${region}/${principalCategory.value}/`
                                : `/fr/${region}/${principalCategory.value}/`
                              : lang === 'ar'
                              ? `/ar/${region}/${principalCategory.value}/`
                              : `/fr/${region}/${principalCategory.value}/`
                          }
                        >
                          <IonText>{`${principalCategory.label} - ${capitalize(formattedRegion)}`}</IonText>
                        </a>
                      )}
                    </div>
                  </div>
                );
              })}
            </IonCol>
          );
        })}
      </IonGrid>
    </div>
  );
};

// ts
export default injectIntl(CategoriesListSearch);
