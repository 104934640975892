import { RouteComponentProps, useLocation } from 'react-router';
import { isPlatform } from '@ionic/react';
import { ImagePicker } from '@ionic-native/image-picker';
import { constants } from 'utils';
import { format, isSameDay } from 'date-fns';
import { Plugins } from '@capacitor/core';
import parametrageService from '../../services/ParametrageService';
import axios from 'axios';

const { Storage } = Plugins;
// var createGuest = require('cross-domain-storage/guest');

export function madFormatter() {
  return new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'MAD',
    currencyDisplay: 'symbol',
    maximumFractionDigits: 2,
  });
}

export function dateFormatter(date: string, local: string = 'fr') {
  const mDate = new Date(date);
  const isSame = isSameDay(mDate, new Date());

  let formattedDate = isSame
    ? (local === 'fr' ? "Aujourd'hui, " : 'اليوم، ') + format(mDate, 'HH:mm')
    : format(mDate, 'dd/MM/yyyy HH:mm');

  return formattedDate;
}

export function decimalFormatter(scale: number) {
  return new Intl.NumberFormat('fr-FR', {
    style: 'decimal',
    maximumFractionDigits: scale,
  });
}

export function copyright() {
  let currentDate = new Date();
  return currentDate.getFullYear() === 2020 ? currentDate.getFullYear() : '2020 - ' + currentDate.getFullYear();
}

export function useQuery(props?: RouteComponentProps) {
  if (props) {
    return new URLSearchParams(props.location.search);
  } else {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return new URLSearchParams(useLocation().search);
  }
}

export function validateEmail(email: string) {
  const expression =
    /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;

  return expression.test(String(email).toLowerCase());
}

export function validatePhone(phone: string | null) {
  const expression = /^((00|\+)\d{1,3}|0)\d{9,12}$/i;

  return expression.test(String(phone).toLowerCase());
}

export function urlDataFormatter(data: object): Array<string> {
  let formData: any = [];
  //
  for (let key in data) {
    // @ts-ignore
    if (Array.isArray(data[key])) {
      // @ts-ignore
      formData = [...formData, ...formatArray(key, data[key])];
    } else {
      // @ts-ignore
      if (typeof data[key] === 'object' && data[key] != null) {
        // @ts-ignore
        formData.push(`${encodeURIComponent(JSON.stringify(data[key]))}`);
      } else {
        // @ts-ignore
        formData.push(`${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`);
      }
    }
  }

  return formData;
}

function formatArray(key: string, data: Array<any>): Array<string> {
  let formData = [];
  for (let aKey in data) {
    if (Array.isArray(data[aKey]) || (typeof data[aKey] === 'object' && data[aKey] != null)) {
      // @ts-ignore
      formData.push(`${encodeURIComponent(key)}[${encodeURIComponent(aKey)}]${formatArray(`[${aKey}]`, data[aKey])}`);
    } else {
      formData.push(`${encodeURIComponent(key)}[${encodeURIComponent(aKey)}]=${encodeURIComponent(data[aKey])}`);
    }
  }
  return formData;
}

export async function setUserToken(token: string) {
  window.localStorage.setItem('@flaha.userToken', token);
  return token;
  // if (isPlatform('capacitor') || isPlatform('cordova')) {
  //     return await NativeStorage.setItem('@flaha.userToken', token);
  // } else {
  //     window.localStorage.setItem('@flaha.userToken', token);
  //     return token;
  // }
}

/*
export function initProfile() {
  var bazStorage = createGuest(process.env.REACT_APP_MAIN_DOMAIN_URL);
  if (window.location.pathname !== '/logout') {
    bazStorage.get('@flaha.userToken', function (tokenError, tokenValue) {
      if (tokenError) {
        console.log(tokenError);
      } else {
        window.localStorage.setItem('@flaha.userToken', tokenValue);
        let bearer = 'Bearer ' + tokenValue;
        let url = process.env.REACT_APP_API_URL + constants.userProfileGet;
        const headers = new Headers({
          Authorization: bearer,
        });
        fetch(url, {
          method: 'GET',
          credentials: 'same-origin',
          headers: headers,
        })
          .then((request) => {
            return request.json();
          })
          .then((jsonRequest) => {
            if (jsonRequest) {
              setUserProfile(
                jsonRequest.email,
                jsonRequest.nom,
                jsonRequest.telephone,
                jsonRequest.type,
                jsonRequest.imageUrl,
                jsonRequest.admin ? 'true' : 'false',
                jsonRequest.moderator ? 'true' : 'false',
                jsonRequest.roles ? jsonRequest.roles : []
              );
            }
          });
      }
    });
  }
}

export function initLanguage() {
  var bazStorage = createGuest(process.env.REACT_APP_MAIN_DOMAIN_URL);

  bazStorage.get('@flaha.language', function (langError, langValue) {
    if (langError) {
      console.log(langError);
    } else {
      window.localStorage.setItem('@flaha.language', langValue);
    }
  });
}
 */
export function setUserProfile(
  email: string | null,
  nom: string | null,
  telephone: string | null,
  type: string | null,
  imageUrl: string | null,
  isAdmin: string | 'false',
  isModerator: string | 'false',
  roles: [any] | []
) {
  let data = {
    email: email ? email : '',
    nom: nom ? nom : '',
    telephone: telephone ? telephone : '',
    type: type ? type : '',
    imageUrl: imageUrl ? imageUrl : '',
    isAdmin: isAdmin ? isAdmin : 'false',
    isModerator: isModerator ? isModerator : 'false',
    roles: roles ? roles : [],
  };
  window.localStorage.setItem('@flaha.userLoginEmail', data.email);
  window.localStorage.setItem('@flaha.userLoginNom', data.nom);
  window.localStorage.setItem('@flaha.userLoginTelephone', data.telephone);
  window.localStorage.setItem('@flaha.userLoginType', data.type);
  window.localStorage.setItem('@flaha.userLoginImageUrl', data.imageUrl);
  window.localStorage.setItem('@flaha.userAdmin', data.isAdmin);
  window.localStorage.setItem('@flaha.userModerator', data.isModerator);
  window.localStorage.setItem('@flaha.userRoles', JSON.stringify(data.roles));
  return data;
}

export function getUserProfile() {
  let data = {
    email: window.localStorage.getItem('@flaha.userLoginEmail'),
    nom: window.localStorage.getItem('@flaha.userLoginNom'),
    telephone: window.localStorage.getItem('@flaha.userLoginTelephone'),
    type: window.localStorage.getItem('@flaha.userLoginType'),
    imageUrl: window.localStorage.getItem('@flaha.userLoginImageUrl'),
    isAdmin: window.localStorage.getItem('@flaha.userAdmin'),
    isModerator: window.localStorage.getItem('@flaha.userModerator'),
    roles: window.localStorage.getItem('@flaha.userRoles'),
  };
  return data;
}

export function checkRoleFct(role: string) {
  let roles = JSON.parse(localStorage.getItem('@flaha.userRoles'));
  let existRole = false;
  roles
    ? roles.map((row) => {
        if (row.role.toLowerCase() === role) {
          existRole = true;
        }
      })
    : (existRole = false);
  return existRole;
}

export function removeUserProfile() {
  window.localStorage.removeItem('@flaha.userLoginEmail');
  window.localStorage.removeItem('@flaha.userLoginNom');
  window.localStorage.removeItem('@flaha.userLoginTelephone');
  window.localStorage.removeItem('@flaha.userLoginType');
  window.localStorage.removeItem('@flaha.userLoginImageUrl');
}

export async function getUserToken() {
  return window.localStorage.getItem('@flaha.userToken');
  // if (isPlatform('capacitor') || isPlatform('cordova')) {
  //     console.log('capacitor');
  //     return await NativeStorage.getItem('@flaha.userToken');
  // } else {
  //     return window.localStorage.getItem('@flaha.userToken');
  // }
}

export function authHeader() {
  const userToken = localStorage.getItem('@flaha.userToken');
  let token = null;
  if (userToken) token = userToken;

  if (token) {
    return { Authorization: 'Bearer ' + token }; // for Spring Boot back-end
  } else {
    return {};
  }
}

export function isProduction(): boolean {
  return process.env.REACT_APP_API_URL === 'https://flaha.ma:8001';
}

export async function checkUserAdmin() {
  return window.localStorage.getItem('@flaha.userAdmin');
}

export async function checkUserModerator() {
  return window.localStorage.getItem('@flaha.userModerator');
}

export async function removeUserToken() {
  window.localStorage.removeItem('@flaha.userToken');
  // if (isPlatform('capacitor') || isPlatform('cordova')) {
  //     NativeStorage.remove('@flaha.userToken');
  // } else {
  //     window.localStorage.removeItem('@flaha.userToken');
  // }
}

export function makeblob(dataURL: any) {
  const BASE64_MARKER = ';base64,';
  const parts = dataURL.split(BASE64_MARKER);
  const contentType = parts[0].split(':')[1];
  const raw = window.atob(parts[1]);
  const rawLength = raw.length;
  const uInt8Array = new Uint8Array(rawLength);

  for (let i = 0; i < rawLength; ++i) {
    uInt8Array[i] = raw.charCodeAt(i);
  }

  return new Blob([uInt8Array], { type: contentType });
}

export function getFileType(dataURL: any, index: Number) {
  const BASE64_MARKER = ';base64,';
  const imageType = dataURL.split(BASE64_MARKER)[0].substring('data:image/'.length);
  return 'image_' + index + '.' + imageType;
}

export async function setLanguage(lang: string) {
  if (isPlatform('capacitor') || isPlatform('cordova')) {
    const langReq = await Storage.set({
      key: '@flaha.language',
      value: lang,
    });
    return langReq;
  } else {
    window.localStorage.setItem('@flaha.language', lang);
    return lang;
  }
}

export async function getLanguage() {
  if (isPlatform('capacitor') || isPlatform('cordova')) {
    const { value } = await Storage.get({ key: '@flaha.language' });
    return value;
  } else {
    return window.localStorage.getItem('@flaha.language');
  }
}

export async function setLocalAppVersionUtil(appVersion: string) {
  const appVersionPromise = await Storage.set({
    key: '@flaha.appVersion',
    value: appVersion,
  });
  return appVersionPromise;
}

export async function getLocalAppVersionUtil() {
  const { value } = await Storage.get({ key: '@flaha.appVersion' });
  return value;
}

export function getBase64(file: Blob, callback: (result: string | ArrayBuffer | null) => void) {
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    if (callback) callback(reader.result);
  };
  reader.onerror = function (error) {
    console.log('Error: ', error);
  };
}

export async function getSEOParametre(cle: string) {
  const url = `${process.env.REACT_APP_API_URL}${constants.getParametrebyCle}?cle=${cle}`;
  const headers = new Headers({
    'Content-Type': 'application/json',
    SameSite: 'secure',
  });

  const response = await fetch(url, {
    method: 'GET',
    credentials: 'same-origin',
    headers: headers,
  });
  const body = await response.json();

  return body;
}

export async function getSEOData(cle: string, cancelToken) {
  try {
    let params = {};
    params['cle'] = cle;

    const options = {
      headers: {
        'Content-Type': 'application/json',
      },
      params: params,
      cancelToken: cancelToken,
    };

    const res = await parametrageService.getSEO(options);
    return res.data;
  } catch (err) {
    if (!axios.isCancel(err)) throw new Error(err.message);
  }
}

export async function getQAParametre(cleQuestion: string, cleAnswer: string) {
  const urlForQuestion = `${process.env.REACT_APP_API_URL}${constants.getParametrebyCle}?cle=${cleQuestion}`;
  const urlForAnswer = `${process.env.REACT_APP_API_URL}${constants.getParametrebyCle}?cle=${cleAnswer}`;
  const headers = new Headers({
    'Content-Type': 'application/json',
    SameSite: 'secure',
  });

  const responseQuestion = await fetch(urlForQuestion, {
    method: 'GET',
    credentials: 'same-origin',
    headers: headers,
  });
  const responseAnswer = await fetch(urlForAnswer, {
    method: 'GET',
    credentials: 'same-origin',
    headers: headers,
  });
  const bodyQuestion = await responseQuestion.json();
  const bodyAnswer = await responseAnswer.json();

  return {
    question: bodyQuestion,
    answer: bodyAnswer,
  };
}

export async function getMaxAndMinPriceByCategory(categoryId: number) {
  const url = `${process.env.REACT_APP_API_URL}${constants.getMaxAndMinPriceByCategory}?id=${categoryId}`;

  const headers = new Headers({
    'Content-Type': 'application/json',
    SameSite: 'secure',
  });

  const response = await fetch(url, {
    method: 'GET',
    credentials: 'same-origin',
    headers: headers,
  });

  return await response.json();
}

export async function getCategory(categorySlug, type) {
  let urlCategory;
  if (type === 'SEARCH') {
    //console.log(categorySlug);
    urlCategory = `${process.env.REACT_APP_API_URL}${constants.categoryById}?id=${categorySlug}`;
  } else {
    urlCategory = `${process.env.REACT_APP_API_URL}${constants.categoryBySlug.replace('__slug__', categorySlug)}`;
  }
  const response = await fetch(urlCategory);
  const body = await response.json();

  return body;
}

export async function getVille(villeSlug, type) {
  let urlville;
  if (type === 'SEARCH') {
    //console.log(villeSlug);
    urlville = `${process.env.REACT_APP_API_URL}${constants.regionById}?id=${villeSlug}`;
  } else {
    urlville = `${process.env.REACT_APP_API_URL}${constants.cityBySlug.replace('__slug__', villeSlug)}`;
  }
  const response = await fetch(urlville);
  const body = await response.json();

  return body;
}

export function getDeviceStoragePermission(callback: (result: any) => void) {
  /* if (isPlatform('android')) {
                AndroidPermissions
                    .checkPermission(AndroidPermissions.PERMISSION.MANAGE_EXTERNAL_STORAGE)
                    .then((result) => {
                        if (result.hasPermission) {
                            callback(true);
                        } else {
                            AndroidPermissions
                                .requestPermission(AndroidPermissions.PERMISSION.MANAGE_EXTERNAL_STORAGE)
                                .then((result) => {
                                    if (result.hasPermission) {
                                        callback(true);
                                    } else {
                                        callback(false);
                                    }
                                })
                                .catch((error) => {
                                    console.error(error.message, error);
                                    callback(false);
                                })
                        }
                    })
                    .catch((e) => {
                        AndroidPermissions
                            .requestPermission(AndroidPermissions.PERMISSION.MANAGE_EXTERNAL_STORAGE)
                            .then((result) => {
                                if (result.hasPermission) {
                                    callback(true);
                                } else {
                                    callback(false);
                                }
                            })
                            .catch((error) => {
                                console.error(error.message, error);
                                callback(false);
                            })
                    });
            } else {}*/
  if (isPlatform('capacitor')) {
    ImagePicker.hasReadPermission()
      .then((permission) => {
        if (permission) {
          callback(permission);
        } else {
          ImagePicker.requestReadPermission().catch((error) => {
            console.error(error);
            callback(false);
          });
        }
      })
      .catch((e) => {
        ImagePicker.requestReadPermission().catch((error) => {
          console.error(error);
          callback(false);
        });
      });
  }
}

// export async function setUserToken(token: string) {
//     return await SecureStorage.create('@flaha').then(async (storage: SecureStorageObject) => {
//         return await storage.set('userToken', token);
//     }).catch(async (error)=>{
//         return await error;
//     });
// }
//
// export async function getUserToken() {
//     return await SecureStorage.create('@flaha').then(async (storage: SecureStorageObject) => {
//         return await storage.get('userToken');
//     }).catch(async (error)=>{
//         return await error;
//     });
// }
//
//
//
// export async function removeUserToken() {
//     return await SecureStorage.create('@flaha').then(async (storage: SecureStorageObject) => {
//         return await storage.remove('userToken');
//     }).catch(async (error)=>{
//         return await error;
//     });
// }
