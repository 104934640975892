import React, { useEffect, useState } from 'react';
import { IonButton, IonButtons, IonCard, IonContent, IonGrid, IonModal, IonPage, IonToolbar } from '@ionic/react';
import './CampaignSideBarButton.scss';
import { Campaign } from 'utils/Types/types';
import CampaignService from '../../services/CampaignService';
import parametrageService from '../../services/ParametrageService';
import { constants } from 'utils';
import BoostAnnonceModalContent from '../BoostAnnonceModalContent/BoostAnnonceModalContent';
import FileService from '../../services/FileService';
import { FormattedMessage } from 'react-intl';

interface CampaignSideBarButtonProps {
  lang: string;
}

const CampaignSideBarButton: React.FC<CampaignSideBarButtonProps> = ({ lang }) => {
  const [campaign, setCampaign] = useState<Campaign>(undefined);

  const [buttonClicked, setButtonClicked] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const getCampaign = (id) => {
      const options = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      CampaignService.getById(id, options)
        .then((value) => {
          setCampaign(value.data);
        })
        .catch((reason) => {
          throw new Error(reason.message);
        });
    };

    parametrageService
      .get({
        options: {
          headers: {
            'Content-Type': 'application/json',
          },
          params: { cle: constants.MOBILE_POPUP_ADUNIT_CAMPAIGN },
        },
      })
      .then((res) => {
        if (res.data.valeurFr) {
          getCampaign(res.data.valeurFr);
        }
      });
  }, []);

  return (
    <>
      {campaign?.active && (
        <div className="sidebar">
          <div className="campaign">
            <a
              href={'#'}
              onClick={(e) => {
                e.preventDefault();
                if (buttonClicked) {
                  setIsOpen(true);
                }
                setButtonClicked(true);
              }}
            >
              <p>
                {lang === 'fr' ? campaign?.descriptionFr : campaign?.descriptionAr}
                <picture>
                  <source type="image/webp" srcSet={'assets/logo-app-flaha.webp'} />
                  <img width={'30'} loading="lazy" src={'assets/logo-app-flaha.png'} alt={'Flaha.ma'} />
                </picture>
              </p>
            </a>
          </div>
          <IonModal
            isOpen={isOpen}
            onDidDismiss={() => {
              setIsOpen(false);
              setButtonClicked(false);
            }}
            cssClass="my-custom-class"
          >
            <IonContent fullscreen>
              <IonToolbar>
                <IonButtons slot={'end'}>
                  <IonButton
                    onClick={() => {
                      setIsOpen(false);
                      setButtonClicked(false);
                    }}
                  >
                    <FormattedMessage id={'close'} />
                  </IonButton>
                </IonButtons>
              </IonToolbar>
              <div className={'d-flex flex-column align-items-center mx-1'} style={{ gap: '12px' }}>
                <picture>
                  <source
                    type="image/webp"
                    srcSet={FileService.serveCampaignTallBannerWebpPath(campaign?.tallBannerImagePath)}
                  />
                  <img
                    className={'img-fluid'}
                    loading="lazy"
                    decoding={'async'}
                    src={FileService.serveCampaignTallBannerPath(campaign?.tallBannerImagePath)}
                    alt={`${lang === 'fr' ? campaign?.sponsorFr : campaign?.sponsorAr} - ${campaign?.id}`}
                  />
                </picture>
                <IonButton href={`/${lang}/contact-commercial/${campaign?.slug}.htm`} size={'small'}>
                  {lang === 'fr' ? campaign?.buttonTitleFr : campaign?.buttonTitleAr}
                </IonButton>
              </div>
            </IonContent>
          </IonModal>
        </div>
      )}
    </>
  );
};

export default CampaignSideBarButton;
