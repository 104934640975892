import React, { Component, MouseEvent } from 'react';
import {
  IonBackButton,
  IonBadge,
  IonButton,
  IonButtons,
  IonCol,
  IonHeader,
  IonIcon,
  IonLabel,
  IonMenuToggle,
  IonRouterLink,
  IonRow,
  IonToolbar,
  isPlatform,
} from '@ionic/react';
import {
  addOutline,
  chevronBackOutline,
  chevronForwardOutline,
  closeCircleOutline,
  menu,
  personCircleOutline,
  searchOutline,
  storefrontOutline,
} from 'ionicons/icons';
import { Helmet } from 'react-helmet';
import './Header.scss';
import { FlahaProps, FlahaState } from 'utils/Interfaces/Interfaces';
import { FormattedMessage } from 'react-intl';
import { constants, Events } from 'utils';
import { getLanguage, getUserProfile, getUserToken, setLanguage } from 'utils/Functions/Functions';
import { connect } from 'react-redux';
import shopService from '../../services/ShopService';
import { Shop } from 'utils/Types/types';
import NotificationShop from '../NotificationShop/NotificationShop';
import { InputAdornment } from '@material-ui/core';
import NotificationFundRequest from '../NotificationFundRequest/NotificationFundRequest';
import NotificationBoostRequest from '../NotificationBoostRequest/NotificationBoostRequest';
import TopHeader from '../TopHeader/TopHeader';
import NotificationLeads from '../NotificationLeads/NotificationLeads';
import NotificationTotal from '../NotificationTotal/NotificationTotal';
import MobileTopBanner from '../MobileTopBanner/MobileTopBanner';

const regionCategorySearchKey = '@flaha.regionCategorySearch';
const regionCategoryKey = '@flaha.regioncategory';

class Header extends Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {
      lang: 'ar',
      userToken: null,
      headerShowLang: false,
      headerShowLangTwo: false,
      headerShowLangTree: false,
      showBanner: true,
      envirenement: '',
      userShops: [],
    };
    this.handleLangToggle = this.handleLangToggle.bind(this);
    this.checkRole = this.checkRole.bind(this);
  }

  UNSAFE_componentWillMount(): void {
    getLanguage()
      .then((lang) => {
        if (lang && (lang === 'fr' || lang === 'ar')) {
          this.setState(
            {
              lang: lang,
            },
            () => {
              Events.publish(constants.lang_change, { lang: lang });
            }
          );
        }
      })
      .catch((error) => {
        console.error(`Error has been thrown (${error.message})`, error);
      });
  }

  getRequestParams(filter) {
    let params = {};
    if (filter) {
      params['filter'] = filter;
    }
    return params;
  }

  getUserShops() {
    const filter = {
      shopOwnerEmail: String(getUserProfile().email),
      disabled: false,
      moderated: null,
    };

    const params = this.getRequestParams(JSON.stringify(filter));
    const options = {
      headers: {
        'Content-Type': 'application/json',
      },
      params: params,
    };

    shopService
      .getAll(options)
      .then((value) => {
        const { shops } = value.data;
        this.setState({ userShops: [...shops] });
      })
      .catch((reason) => {
        console.error(reason.message);
      });
  }

  componentDidMount(): void {
    Events.subscribe(constants.lang_change, ({ lang }) => {
      this.setState({
        lang: lang,
      });
    });
    this.getUserShops();
    this.getToken();
    this.getEnvirenement();
  }

  async getToken() {
    await getUserToken().then((data) => {
      if (data === undefined) {
        this.setState({ userToken: null });
      } else {
        this.setState({ userToken: data });
      }
    });
  }

  componentWillUnmount(): void {
    Events.destroy(constants.lang_change);
  }

  // @ts-ignore
  handleLangToggle(event: MouseEvent) {
    event.preventDefault();
    const { type } = event;

    setTimeout(() => {
      this.setState({ headerShowLang: type === 'blur' ? false : !this.state.headerShowLang });
    }, 200);
  }

  async langChange(lang) {
    const language = lang !== 'fr' ? 'fr' : 'ar';
    this.setState({ lang: language });
    setLanguage(language)
      .then((data) => {
        Events.publish(constants.lang_change, { lang: language });
      })
      .catch((error) => {
        console.error(`Error has been thrown (${error.message})`, error);
      });

    const pathname = decodeURIComponent(window.location.pathname);
    const splittedPath = pathname.split('/');

    if (splittedPath.length >= 4) {
      if (
        (pathname.includes(constants.allRegion) ||
          pathname.includes('المغرب') ||
          pathname.includes(constants.allCategories) ||
          (pathname.includes('للبيع') &&
            (pathname.includes('user') === false ||
              pathname.includes('boutiques') === false ||
              pathname.includes('htm') === false))) &&
        (isPlatform('desktop') || isPlatform('mobileweb') || (isPlatform('capacitor') && !this.props.showModalRedux))
      ) {
        const regionCategorieJson = JSON.parse(localStorage.getItem(regionCategorySearchKey));
        const region = splittedPath[2];
        const category = splittedPath[3];
        const regionArab = regionCategorieJson.regionCategorySearch.regionArab;
        const regionFr = regionCategorieJson.regionCategorySearch.regionFr;
        const categorieArab = regionCategorieJson.regionCategorySearch.categorieArab;
        const categorieFr = regionCategorieJson.regionCategorySearch.categorieFr;

        const regionTitre = lang === 'ar' ? regionFr : regionArab;
        const categoryTitre = lang === 'ar' ? categorieFr : categorieArab;

        const allRegionAr = 'المغرب';
        const allRegionFr = 'maroc';
        const allCategoryAr = 'للبيع';
        const allCategoryFr = 'a_vendre';

        if (lang === 'fr') {
          if (region === 'maroc' && category === 'a_vendre') {
            window.location.href = '/' + language + '/' + allRegionAr + '/' + allCategoryAr + '/' + splittedPath[4];
          } else if (region !== 'maroc' && category === 'a_vendre') {
            window.location.href = '/' + language + '/' + regionTitre + '/' + allCategoryAr + '/' + splittedPath[4];
          } else if (region === 'maroc' && category !== 'a_vendre') {
            window.location.href = '/' + language + '/' + allRegionAr + '/' + categoryTitre + '/' + splittedPath[4];
          } else if (region !== 'maroc' && category !== 'a_vendre') {
            window.location.href = '/' + language + '/' + regionTitre + '/' + categoryTitre + '/' + splittedPath[4];
          }
        } else {
          if (region === 'المغرب' && category === 'للبيع') {
            window.location.href = '/' + language + '/' + allRegionFr + '/' + allCategoryFr + '/' + splittedPath[4];
          } else if (region !== 'المغرب' && category === 'للبيع') {
            window.location.href = '/' + language + '/' + regionTitre + '/' + allCategoryFr + '/' + splittedPath[4];
          } else if (region === 'المغرب' && category !== 'للبيع') {
            window.location.href = '/' + language + '/' + allRegionFr + '/' + categoryTitre + '/' + splittedPath[4];
          } else if (region !== 'المغرب' && category !== 'للبيع') {
            window.location.href = '/' + language + '/' + regionTitre + '/' + categoryTitre + '/' + splittedPath[4];
          }
        }
      } else if (pathname.includes('contact-commercial') && (isPlatform('desktop') || isPlatform('mobileweb'))) {
        window.location.href = `/${language}/${splittedPath[2]}/${splittedPath[3]}`;
      } else if (pathname.includes('boutiques') && (isPlatform('desktop') || isPlatform('mobileweb'))) {
        window.location.href = `/${language}/${splittedPath[2]}/`;
      } else if (
        pathname.includes('htm') &&
        pathname.includes('boutique') === false &&
        (isPlatform('desktop') || isPlatform('mobileweb'))
      ) {
        const regionVilleCategorieJson = JSON.parse(localStorage.getItem(regionCategoryKey));
        const regionArab = regionVilleCategorieJson.regionVilleCategoryResults.regionArab;
        const regionFr = regionVilleCategorieJson.regionVilleCategoryResults.regionFr;
        const villeArab = regionVilleCategorieJson.regionVilleCategoryResults.villeArab;
        const villeFr = regionVilleCategorieJson.regionVilleCategoryResults.villeFr;
        const categorieArab = regionVilleCategorieJson.regionVilleCategoryResults.categorieArab;
        const categorieFr = regionVilleCategorieJson.regionVilleCategoryResults.categorieFr;

        const newPathAr =
          '/' + language + '/' + regionArab + '/' + villeArab + '/' + categorieArab + '/' + splittedPath[5];
        const newPathFr = '/' + language + '/' + regionFr + '/' + villeFr + '/' + categorieFr + '/' + splittedPath[5];

        lang === 'fr' ? (window.location.href = newPathAr) : (window.location.href = newPathFr);
      }
    } else if (
      pathname.includes('htm') &&
      pathname.includes(lang) &&
      (isPlatform('desktop') || isPlatform('mobileweb'))
    ) {
      window.location.href = `/${language}/${splittedPath[2]}`;
    } else if (pathname.includes('boutique-dashboard')) {
      window.location.href = pathname + window.location.search;
    } else {
      window.location.href = pathname;
    }
  }

  onClickSmartBanner() {
    var ua = navigator.userAgent.toLowerCase();
    var isAndroid = ua.indexOf('android') > -1;
    if (isAndroid) {
      setTimeout(function () {
        window.location.href = 'https://play.google.com/store/apps/details?id=ma.flaha';
      }, 1000);
      window.location.href = 'ma.flaha://';
    }
  }

  checkRole(role: string) {
    let roles = JSON.parse(localStorage.getItem('@flaha.userRoles'));
    let existRole = false;
    roles
      ? roles.map((row) => {
          if (row.role.toLowerCase() === role) {
            existRole = true;
          }
        })
      : (existRole = false);
    return existRole;
  }

  getEnvirenement() {
    if (process.env.REACT_APP_API_URL === 'https://flaha.ma:8001') {
      this.setState({ envirenement: 'Production' });
    } else {
      this.setState({ envirenement: 'Recette' });
    }
  }

  render() {
    const {
      userToken,
      headerShowLang,
      headerShowLangTwo,
      headerShowLangTree,
      lang,
      showBanner,
      envirenement,
      userShops,
    } = this.state;
    // const verifyToken = this.verifyTokenUser();
    let showBackButton = true;
    const pathname = decodeURIComponent(window.location.pathname);
    const splittedPath = pathname.split('/');
    if (splittedPath.length >= 4) {
      if (
        (pathname.includes(constants.allRegion) ||
          pathname.includes('المغرب') ||
          ((pathname.includes(constants.allCategories) || pathname.includes('للبيع')) &&
            !pathname.includes('verify')) ||
          (!pathname.includes('user') &&
            !pathname.includes('htm') &&
            !pathname.includes('verify') &&
            !pathname.includes('edit'))) &&
        (isPlatform('desktop') || isPlatform('mobileweb') || (isPlatform('capacitor') && !this.props.showModalRedux))
      ) {
        isPlatform('capacitor') ? (showBackButton = false) : (showBackButton = true);
      }
    }
    if (pathname === '/' || pathname === '/ar' || pathname === '/ar') {
      showBackButton = false;
    }

    return (
      <div>
        {isPlatform('mobileweb') && <MobileTopBanner lang={lang} />}
        {isPlatform('android') || isPlatform('ios') ? (
          <IonHeader dir={lang === 'ar' ? 'rtl' : 'ltr'} className="notch-header">
            <IonToolbar>
              <IonButtons slot={lang === 'fr' ? 'start' : 'end'}>
                {isPlatform('capacitor') && (this.props.showModalRedux || this.props.showSearchModalRedux) ? (
                  <IonButton
                    onClick={() => {
                      this.props.setShowModal(false);
                      this.props.setShowSearchModal(false);
                    }}
                    fill="clear"
                  >
                    <IonIcon color={'dark'} size={'large'} icon={chevronBackOutline} />
                  </IonButton>
                ) : showBackButton ? (
                  <IonBackButton defaultHref={'/'} text={''} color={'dark'} />
                ) : null}
              </IonButtons>
              <div className="envirenement-badge">
                {userToken && !this.checkRole('user') ? <IonBadge color="primary">{envirenement}</IonBadge> : null}
              </div>

              <IonRouterLink routerLink={'/'}>
                <div className="flaha-logo-container">
                  <picture className="flaha-logo-pic">
                    <source type="image/webp" srcSet={'assets/Logo_flaha.webp'} />
                    <img className="flaha-logo" loading="lazy" src={'assets/Logo_flaha.svg'} alt={'Flaha.ma'} />
                  </picture>
                </div>
              </IonRouterLink>

              <IonButtons slot={lang === 'fr' ? 'end' : 'start'}>
                <IonMenuToggle menu={'mobileMenu'}>
                  <IonIcon size={'large'} icon={menu} />
                </IonMenuToggle>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
        ) : null}
        {isPlatform('desktop') ? (
          <>
            <TopHeader lang={lang} />
            <header className={'navbar navbar-expand-lg navbar-light bd-navbar'}>
              {this.metaTags()}
              <div className={'container'}>
                {isPlatform('ios') && window.location.pathname !== '/' ? (
                  <IonButtons slot="start">
                    <IonBackButton defaultHref={'/'} text={''} color={'dark'} />
                  </IonButtons>
                ) : isPlatform('capacitor') && this.props.showModalRedux ? (
                  <IonButton onClick={() => this.props.setShowModal(false)} fill="clear" slot="start">
                    <IonIcon
                      color={'dark'}
                      size={'large'}
                      icon={lang === 'fr' ? chevronBackOutline : chevronForwardOutline}
                    />
                  </IonButton>
                ) : (
                  <a
                    id={'headerSearchIcon'}
                    onClick={() => {
                      this.props.setSearch(true);
                      lang === 'ar'
                        ? (window.location.href = `/ar/المغرب/للبيع/`)
                        : (window.location.href = `/fr/maroc/a_vendre/`);
                    }}
                  >
                    <IonIcon size={'large'} icon={searchOutline} />
                    <span>
                      <FormattedMessage id={'menu.search'} />
                    </span>
                  </a>
                )}
                <a className="navbar-brand mr-0 mr-md-2" href="/" aria-label="Flaha">
                  <picture>
                    <source type="image/webp" srcSet={'assets/Logo_flaha.webp'} />
                    <img loading="lazy" src={'assets/Logo_flaha.svg'} alt={'Flaha.ma'} />
                  </picture>
                </a>

                <IonMenuToggle menu={'mobileMenu'}>
                  <IonIcon size={'large'} icon={menu} />
                  <span>
                    <FormattedMessage id={'menu'} />
                  </span>
                </IonMenuToggle>
                <div className={'collapse navbar-collapse'}>
                  <a href={'/sendPost'} className={'navbar-brand mr-0 mr-md-2 add-post'}>
                    <div className={'add-post-content'}>
                      <div>
                        <IonIcon size={'large'} icon={addOutline} />
                      </div>
                      <span>
                        <FormattedMessage id={'menu.post.add'} />
                      </span>
                    </div>
                  </a>
                  <ul className={'navbar-nav ml-auto'}>
                    {/*<li className={'nav-item'}>
                      <IonRouterLink
                        className={'nav-link'}
                        routerLink={lang === 'fr' ? '/fr/maroc/Terrain_Ferme/' : '/ar/المغرب/ارض_مزرعة/'}
                      >
                        <IonIcon size={'large'} src={'/assets/icons/farm-icon.svg'} />
                        <span>
                          <FormattedMessage id={'menu.immo.agricole'} />
                        </span>
                      </IonRouterLink>
                    </li>
                    <li className={'nav-item'}>
                      <IonRouterLink className={'nav-link'} routerLink={`/${lang}/boutiques/`}>
                        <IonIcon size={'large'} icon={storefrontOutline} />
                        <span>
                          <FormattedMessage id={'menu.shops'} />
                        </span>
                      </IonRouterLink>
                    </li>
                    <li className={'nav-item'}>
                      <a href={`/${lang}/neuf/`} className={'nav-link'}>
                        <IonIcon size={'large'} src={'/assets/img/flaha-neuf/tracteur-icon.svg'} />
                        <span>
                          <FormattedMessage id={'flaha.neuf.menu.title'} />
                        </span>
                      </a>
                    </li>*/}
                    <li className={'nav-item'}>
                      <a
                        onClick={() => {
                          this.props.setSearch(true);
                          lang === 'ar'
                            ? (window.location.href = `/ar/المغرب/للبيع/`)
                            : (window.location.href = `/fr/maroc/a_vendre/`);
                        }}
                        className={'nav-link'}
                      >
                        <IonIcon size={'large'} icon={searchOutline} />
                        <span>
                          <FormattedMessage id={'menu.search'} />
                        </span>
                      </a>
                    </li>
                    {userToken === null ? (
                      <li className={'nav-item'}>
                        <a href={'/login'} className={'nav-link'}>
                          <IonIcon size={'large'} icon={personCircleOutline} />
                          <span>
                            <FormattedMessage id={'menu.user.sign.in'} />
                          </span>
                        </a>
                      </li>
                    ) : (
                      <li className={'nav-item dropdown'}>
                        <a
                          type="button"
                          className={'nav-link dropdown-toggle'}
                          onClick={() => {
                            this.setState({ headerShowLang: !headerShowLang });
                            this.setState({ headerShowLangTwo: false });
                            this.setState({ headerShowLangTree: false });
                          }}
                        >
                          <IonIcon size={'large'} icon={personCircleOutline} />
                          <span>{getUserProfile().nom}</span>
                        </a>
                        {(this.checkRole('commercial') || this.checkRole('admin')) && (
                          <div id={'shop-notification-count'}>
                            <NotificationTotal />
                          </div>
                        )}
                        <div
                          className={'dropdown-menu' + (headerShowLang ? ' show' : '')}
                          aria-labelledby="lang"
                          style={{ backgroundColor: 'var(--ion-color-light)' }}
                        >
                          <a
                            href={'/user/profile'}
                            style={lang === 'ar' ? { textAlign: 'right' } : {}}
                            className="dropdown-item"
                          >
                            <span>
                              <FormattedMessage id={'menu.user.profile'} />
                            </span>
                          </a>
                          <a
                            href={'/user/mes-annonces'}
                            style={lang === 'ar' ? { textAlign: 'right' } : {}}
                            className="dropdown-item"
                          >
                            <span>
                              <FormattedMessage id={'user.annonce'} />
                            </span>
                          </a>
                          {userShops?.length > 0 && (
                            <a
                              href={'/user/mes-boutiques'}
                              style={lang === 'ar' ? { textAlign: 'right' } : {}}
                              className="dropdown-item"
                            >
                              <span>
                                <FormattedMessage id={'user.shops'} />
                              </span>
                            </a>
                          )}

                          {/*  <a href={'/user/chat'} className="dropdown-item">
                          <span>
                            <FormattedMessage id={'user.chat'} />
                          </span>
                        </a>*/}
                          {this.checkRole('moderateur') || this.checkRole('admin') || this.checkRole('editeur') ? (
                            <a
                              type="button"
                              className="dropdown-item"
                              onClick={() => {
                                this.setState({ headerShowLang: !headerShowLang });
                                this.setState({ headerShowLangTwo: !headerShowLangTwo });
                              }}
                            >
                              <span style={{ display: 'flex' }}>
                                <FormattedMessage id={'menu.administration'} />
                                <InputAdornment
                                  position="end"
                                  style={
                                    lang === 'ar'
                                      ? { marginRight: '60%', marginTop: '11px' }
                                      : { marginTop: '11px', marginLeft: '23%' }
                                  }
                                >
                                  {lang === 'fr' ? (
                                    <IonIcon icon={chevronForwardOutline} style={{ color: 'rgb(168, 180, 192)' }} />
                                  ) : (
                                    <IonIcon
                                      icon={chevronBackOutline}
                                      style={{ color: 'rgb(168, 180, 192)' }}
                                    ></IonIcon>
                                  )}
                                </InputAdornment>
                              </span>
                            </a>
                          ) : null}

                          {this.checkRole('admin') || this.checkRole('commercial') ? (
                            <a
                              type="button"
                              className="dropdown-item"
                              onClick={() => {
                                this.setState({ headerShowLang: !headerShowLang });
                                this.setState({ headerShowLangTree: !headerShowLangTree });
                              }}
                            >
                              <span style={{ display: 'flex' }}>
                                <FormattedMessage id={'menu.commerciale'} />
                                <InputAdornment
                                  position="end"
                                  style={
                                    lang === 'ar' ? { marginRight: '30%', marginTop: '11px' } : { marginTop: '11px' }
                                  }
                                >
                                  {lang === 'fr' ? (
                                    <IonIcon
                                      icon={chevronForwardOutline}
                                      style={{ color: 'rgb(168, 180, 192)' }}
                                    ></IonIcon>
                                  ) : (
                                    <IonIcon
                                      icon={chevronBackOutline}
                                      style={{ color: 'rgb(168, 180, 192)' }}
                                    ></IonIcon>
                                  )}
                                </InputAdornment>
                              </span>
                            </a>
                          ) : null}

                          <a
                            href={'/logout'}
                            style={lang === 'ar' ? { textAlign: 'right' } : {}}
                            className="dropdown-item"
                          >
                            <span>
                              <FormattedMessage id={'menu.user.sign.out'} />
                            </span>
                          </a>
                        </div>
                        <div
                          className={'dropdown-menu' + (headerShowLangTwo ? ' show' : '')}
                          aria-labelledby="lang"
                          style={{ backgroundColor: 'var(--ion-color-light)' }}
                        >
                          {this.checkRole('moderateur') || this.checkRole('admin') || this.checkRole('editeur') ? (
                            <a
                              href={'/user/verify-annonces'}
                              style={lang === 'ar' ? { textAlign: 'right' } : {}}
                              className="dropdown-item"
                            >
                              <span>
                                <FormattedMessage id={'moderator.annonce'} />
                              </span>
                            </a>
                          ) : null}
                          {this.checkRole('moderateur') || this.checkRole('admin') ? (
                            <a
                              href={'/user/Desactiver-annonces'}
                              style={lang === 'ar' ? { textAlign: 'right' } : {}}
                              className="dropdown-item"
                            >
                              <span>
                                <FormattedMessage id={'Desactiver.annonce'} />
                              </span>
                            </a>
                          ) : null}
                          {this.checkRole('moderateur') || this.checkRole('admin') ? (
                            <a
                              href={'/moderator/modifier-annonces-en-masse'}
                              style={lang === 'ar' ? { textAlign: 'right' } : {}}
                              className="dropdown-item"
                            >
                              <span>
                                <FormattedMessage id={'moderator.edit.annonce.en.masse'} />
                              </span>
                            </a>
                          ) : null}
                          {this.checkRole('moderateur') || this.checkRole('admin') ? (
                            <a
                              href={'/moderator/moderate-annonces'}
                              style={lang === 'ar' ? { textAlign: 'right' } : {}}
                              className="dropdown-item"
                            >
                              <span>
                                <FormattedMessage id={'moderator.moderer.annonce'} />
                              </span>
                            </a>
                          ) : null}
                          {this.checkRole('moderateur') || this.checkRole('admin') ? (
                            <a
                              href={'/moderator/liste-contacts'}
                              style={lang === 'ar' ? { textAlign: 'right' } : {}}
                              className="dropdown-item"
                            >
                              <span>
                                <FormattedMessage id={'moderator.liste.contacts'} />
                              </span>
                            </a>
                          ) : null}
                          {this.checkRole('admin') ? (
                            <a
                              href={'/moderator/liste-kitmedia'}
                              style={lang === 'ar' ? { textAlign: 'right' } : {}}
                              className="dropdown-item"
                            >
                              <span>
                                <FormattedMessage id={'moderator.liste.kitmedia'} />
                              </span>
                            </a>
                          ) : null}
                        </div>
                        <div
                          className={'dropdown-menu' + (headerShowLangTree ? ' show' : '')}
                          aria-labelledby="lang"
                          style={{ backgroundColor: 'var(--ion-color-light)' }}
                        >
                          {this.checkRole('commercial') || this.checkRole('admin') ? (
                            <a
                              href={'/user/analytics-dashboard'}
                              className="dropdown-item d-flex justify-content-between"
                            >
                              <span className={'mx-1'}>
                                <FormattedMessage id={'menu.stats.title'} />
                              </span>
                            </a>
                          ) : null}

                          {this.checkRole('commercial') || this.checkRole('admin') ? (
                            <a href={'/user/leads-moderation'} className="dropdown-item d-flex justify-content-between">
                              <span className={'mx-1'}>
                                <FormattedMessage id={'menu.moderate.leads.title'} />
                              </span>
                              <NotificationLeads />
                            </a>
                          ) : null}

                          {this.checkRole('commercial') || this.checkRole('admin') ? (
                            <a href={'/moderator/shops'} className="dropdown-item d-flex justify-content-between">
                              <span className={'mx-1'}>
                                <FormattedMessage id={'shop.moderate.title'} />
                              </span>
                              <NotificationShop />
                            </a>
                          ) : null}

                          <a href={'/moderator/fund-requests'} className="dropdown-item d-flex justify-content-between">
                            <span className={'mx-1'}>
                              <FormattedMessage id={'fund.requests'} />
                            </span>
                            <NotificationFundRequest />
                          </a>
                          <a href={'/moderator/boost-requests'} className="dropdown-item d-flex justify-content-between">
                              <span className={'mx-1'}>
                                <FormattedMessage id={'boost.requests'} />
                              </span>
                              <NotificationBoostRequest />
                            </a>
                        </div>
                      </li>
                    )}
                    <li className={'nav-item langSelector'}>
                      <IonLabel style={{ cursor: 'pointer', margin: '20px' }} onClick={() => this.langChange(lang)}>
                        {lang !== 'fr' ? 'Français' : 'العربية'}
                      </IonLabel>
                    </li>
                  </ul>
                </div>
              </div>
            </header>
          </>
        ) : null}
      </div>
    );
  }

  metaTags() {
    const { lang } = this.state;
    return (
      <Helmet>
        <html lang={lang} dir={lang === 'fr' ? 'LTR' : 'RTL'} />
        <link rel="shortcut icon" type="image/svg" href="/assets/logo-flaha.webp" />
      </Helmet>
    );
  }
}

interface Props extends FlahaProps {
  // getToken?: string | null | undefined;
  showModalRedux: boolean;
  setShowModal: (showModal) => void;
  searchClickedRedux: boolean;
  setSearch: (searchClicked) => void;
  regionsRedux: any;
  categoriesRedux: any;
  showSearchModalRedux: boolean;
  setShowSearchModal: (showModal) => void;
}

interface State extends FlahaState {
  userToken?: string | null | undefined;
  headerShowLang: boolean;
  headerShowLangTwo: boolean;
  headerShowLangTree: boolean;
  showBanner: boolean;
  envirenement: string | null | undefined;
  userShops: Array<Shop>;
}

// Redux mappers
const mapStateToProps = (state) => {
  return {
    showModalRedux: state.setShowModalReducer.showModalRedux.showModal,
    searchClickedRedux: state.setSearchReducer.search.searchClickedRedux,
    regionsRedux: state.saveRegionsCategoriesReducer.Regions_Categories.regions,
    categoriesRedux: state.saveRegionsCategoriesReducer.Regions_Categories.categories,
    showSearchModalRedux: state.setShowSearchModalReducer.showSearchModalRedux.showSearchModal,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    // dispatching plain actions
    setShowModal: (showModal) => dispatch({ type: 'SET_SHOWMODAL', payload: { showModal: showModal } }),
    setSearch: (searchClickedRedux) =>
      dispatch({
        type: 'SET_SEARCH_CLICKED',
        payload: { searchClickedRedux: searchClickedRedux },
      }),
    setShowSearchModal: (showSearchModal) =>
      dispatch({
        type: 'SET_SHOW_SEARCH_MODAL',
        payload: { showSearchModal: showSearchModal },
      }),
  };
};

// export default injectIntl(Header);
// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(Header);
