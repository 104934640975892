import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { IonButton, IonButtons, IonContent, IonModal, IonToolbar, isPlatform } from '@ionic/react';
import { FormattedMessage } from 'react-intl';
import FileService from '../../services/FileService';
import { Campaign } from 'utils/Types/types';
import CampaignService from '../../services/CampaignService';
import parametrageService from '../../services/ParametrageService';
import { constants } from 'utils';

interface WebPopupBannerProps {
  lang: string;
}

const WebPopupBanner: React.FC<WebPopupBannerProps> = ({ lang }) => {
  const [cookies, setCookie] = useCookies(['isOpen']);
  const [campaign, setCampaign] = useState<Campaign>(undefined);

  function onClose() {
    setCookie('isOpen', '6zlw8875ClS^', { path: '/' });
  }

  useEffect(() => {
    const getCampaign = (id) => {
      const options = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      CampaignService.getById(id, options)
        .then((value) => {
          setCampaign(value.data);
        })
        .catch((reason) => {
          throw new Error(reason.message);
        });
    };

    parametrageService
      .get({
        options: {
          headers: {
            'Content-Type': 'application/json',
          },
          params: { cle: constants.MOBILE_POPUP_ADUNIT_CAMPAIGN },
        },
      })
      .then((res) => {
        if (res.data.valeurFr) {
          getCampaign(res.data.valeurFr);
        }
      });
  }, []);

  return (
    <>
      {campaign?.active && (
        <IonModal
          isOpen={cookies.isOpen !== '6zlw8875ClS^'}
          onDidDismiss={() => {
            onClose();
          }}
          cssClass="my-custom-class"
        >
          <IonContent fullscreen>
            <IonToolbar>
              <IonButtons slot={'end'}>
                <IonButton
                  onClick={() => {
                    onClose();
                  }}
                >
                  <FormattedMessage id={'close'} />
                </IonButton>
              </IonButtons>
            </IonToolbar>
            <div className={'d-flex flex-column align-items-center mx-1'}>
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    isPlatform('mobileweb')
                      ? FileService.serveCampaignTallBannerWebpPath(campaign?.tallBannerImagePath)
                      : FileService.serveCampaignBannerWebpPath(campaign?.bannerImagePath)
                  }
                />
                <img
                  className={'img-fluid'}
                  loading="lazy"
                  decoding={'async'}
                  src={
                    isPlatform('mobileweb')
                      ? FileService.serveCampaignTallBannerPath(campaign?.tallBannerImagePath)
                      : FileService.serveCampaignBannerPath(campaign?.bannerImagePath)
                  }
                  alt={`${lang === 'fr' ? campaign?.sponsorFr : campaign?.sponsorAr} - ${campaign?.id}`}
                />
              </picture>
              <IonButton
                href={`/${lang}/contact-commercial/${campaign?.slug}.htm`}
                size={'small'}
                onClick={() => onClose()}
              >
                {lang === 'fr' ? campaign?.buttonTitleFr : campaign?.buttonTitleAr}
              </IonButton>
            </div>
          </IonContent>
        </IonModal>
      )}
    </>
  );
};

export default WebPopupBanner;
